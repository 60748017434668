import React from 'react'
import CV from "../../Assets/Maher_Salah_Lebenslauf.pdf"
import {BsLinkedin , BsInstagram , BsGithub} from "react-icons/bs"
import {FaArtstation} from "react-icons/fa"

const CTA = () => {
  return (
    <div className='cta'>
        {/* <a href={CV} download className='btn'>Download CV</a> */}
        <a >Let's get in Touch</a>
        <a href="https://www.linkedin.com/in/maher-salah-4940271b9/" target="_blank" ><BsLinkedin size={30}/></a>
        <a href="https://github.com/maherhms" target="_blank" className='.btn'><BsGithub size={30}/></a>
        <a href="https://www.artstation.com/youngskills" target="_blank"><FaArtstation size={30}/></a>
        <a href="https://instagram.com/devnest.contact" target="_blank"><BsInstagram size={30}/></a>
    </div>
  )
}

export default CTA