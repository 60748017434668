import React from 'react'
import { useState } from "react";
import { Link } from "react-router-dom";
import "./ThreedGallery.css"
import img1 from "../../Assets/3D/CuteBed1.png"
import img2 from "../../Assets/3D/KingApartement.png"
import img3 from "../../Assets/3D/KingBed1.png"
import img4 from "../../Assets/3D/QueenBedroom.png"
import img5 from "../../Assets/3D/QueenLivingRoom.png"
import img6 from "../../Assets/3D/emperorBedroom1.png"
import img7 from "../../Assets/3D/emperorKitchen.jpg"
import img8 from "../../Assets/3D/emperorlivingroom.png"

import {IoClose} from 'react-icons/io5'
import {BiArrowBack} from 'react-icons/bi'
import {IoMdArrowForward} from "react-icons/io"

function ThreedGallery() {

    let data = [
        {
            id: 1,
            imgSrc: img1,
        },
        {
            id: 2,
            imgSrc: img2,
        },
        {
            id: 3,
            imgSrc: img3,
        },
        {
            id: 4,
            imgSrc: img4,
        },
        {
            id: 5,
            imgSrc: img5,
        },
        {
            id: 6,
            imgSrc: img6,
        },
        {
            id: 7,
            imgSrc: img7,
        },
        {
            id: 8,
            imgSrc: img8,
        },
    ]

    let [model , setModel] = useState(false);
    let [tmpImgSrc , setTmpImgSrc] = useState('');
    let getImg = (imgSrc) => {
        setTmpImgSrc(imgSrc);
        setModel(true);
    }

  return (
      <>
      <div className={model? "model open" : "model"}>
         <img src={tmpImgSrc} />
         <IoClose onClick={()=> setModel(false)}/>
      </div>
      
      <div className='links'>
      <Link to="/">
        <div className='homebtn'>
                <BiArrowBack size={20}/>
                <h3>Home</h3>
        </div>
      </Link>
      <Link to="/interior-design">
        <div className='homebtn'>
                <h3>Order</h3>
                <IoMdArrowForward size={20}/>
        </div>
      </Link>
      </div>
      
      <div className='title'>
          <h1>3D Isometric Design</h1>
      </div>
      <div className='gallery'>
            {data.map((item , index) => {
                return(
                    <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                        <img src={item.imgSrc} style={{width:'100%'}} />
                    </div>
                )
            })}
        </div>
    </>
  )
}

export default ThreedGallery