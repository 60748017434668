import React , {useState} from 'react'
import "./StreetBites.css"
import { useLocation } from 'react-router-dom';
import logo from "../../Assets/transparent.png"
import artboard from "../../Assets/StreetBite/Artboard.png"
import m0 from "../../Assets/StreetBite/StreetBite (0).jpg"
import m1 from "../../Assets/StreetBite/StreetBiteCover.jpg"
import m2 from "../../Assets/StreetBite/StreetBite (1).jpg"
import m3 from "../../Assets/StreetBite/StreetBite (3).jpg"
import m4 from "../../Assets/StreetBite/StreetBite (5).jpg"
import m5 from "../../Assets/StreetBite/StreetBite (6).jpg"
import cheeseicon from "../../Assets/StreetBite/CheeseBlock.png"
import tomatoicon from "../../Assets/StreetBite/Tomato.png"
import cabbageicon from "../../Assets/StreetBite/Cabbage.png"
import meaticon from "../../Assets/StreetBite/MeatPattyUncooked.png"
import breadicon from "../../Assets/StreetBite/Bread.png"
import burgerso from "../../Assets/StreetBite/BURGERRECIPESO.png"
import saladso from "../../Assets/StreetBite/SALADRECIPESO.png"
import cheeseburgerso from "../../Assets/StreetBite/CHEESEBURGERRECIPESO.png"
import mushroomburgerso from "../../Assets/StreetBite/MUSHROOMBURGERRECIPESO.png"
import hawaiiburgerso from "../../Assets/StreetBite/HAWAIIMBURGERRECIPESO.png"
import megaburgerso from "../../Assets/StreetBite/MEGABURGERRECIPESO.png"
import {IoLogoSteam} from "react-icons/io5"
import { BiFontSize } from 'react-icons/bi';

function StreetBites() {
    const {search} = useLocation();
    const [activeNav, setActiveNav]= useState("#presentaion");
    const [nav, setNav]= useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 50){
            setNav(true);
        } 
        else {
            setNav(false);
        }
    }

    window.addEventListener('scroll', changeBackground)

  return (
    <body id="contained-streetbites" className="App">
            <div id = "main-streetbites">
                <nav className={activeNav ? "nav-active" : "#contained-drink-app"}>
                    <a href="/" className="logo">
                        <img src={logo} alt="" />
                    </a>
                    <input type="checkbox" className="menu-btn" id="menu-btn" />
                    <label className="menu-icon" for="menu-btn">
                        <span className="nav-icon"></span>
                    </label>
                    <ul className="menu">
                        <li><a href="/#" onClick={() => setActiveNav("/#")} className={activeNav === "#" ? "active" : ""}>Home</a></li>
                        <li><a href="/#/streetbitesroadmap" onClick={() => setActiveNav("/#/streetbites/")} className={activeNav === "#Roadmap" ? "active" : ""}>Roadmap</a></li>
                        <li><a href="/#/streetbitesupdates/" onClick={() => setActiveNav("/#/streetbitesupdates/")} className={activeNav === "#Updates" ? "active" : ""}>Updates</a></li>
                    </ul>
                </nav>
            </div>
            <div className="name-streetbites">
                <h1><span>StreetBites</span> for challenging fast paced cooking fun</h1>
                <p className="details">Coming to Steam this September 2024</p>
                <a href="" className="cv-btn-streetbites">Coming Soon <pre> </pre><IoLogoSteam/></a>
            </div>
            
            <div className='blog-streetbites'>
                <div className= 'blog-content-streebites'>
                    <li>
                    <h2>Story</h2>
                    <h2>Main Features</h2>
                    <h2>Missions</h2>
                    <h2>Upcoming features</h2>
                    <h2>Risks and Challenges</h2>
                    </li>
                </div>
                <div className= 'mainfeatures-streetbites'>
                    <h1>Story</h1>
                    <p>Embark on a culinary journey in "Street Bites Odyssey," a food truck simulation that challenges players to become the ultimate mobile chef entrepreneur. 
                    Your goal is to build and customize your food truck empire by catering to various events and satisfying the diverse tastes of customers across a bustling city.</p>
                    <h1>Main Features:</h1>
                    <h4>Mobile Culinary Mastery: Take control of your food truck and navigate through the city streets, discovering new locations and events where hungry customers await your delicious creations.</h4>
                    <img src={m1}></img>
                    <h4>Satisfy Customer Demands: Serve a variety of dishes to meet the unique preferences and cravings of different customers. Success depends on your ability to quickly prepare and deliver the right orders.</h4>
                    <img src={m4}></img>
                    <h4>Earn and Manage Funds: Collect money from satisfied customers to fund upgrades for your food truck, kitchen equipment, and recipes.
                         Make strategic decisions on where to invest to enhance efficiency and unlock new culinary possibilities.</h4>
                    <img src={m0}></img>
                    <h4>Customization and Upgrades: Personalize your food truck with a range of upgrades, from advanced cooking appliances to eye-catching aesthetics. 
                        Experiment with different layouts and designs to attract more customers and increase earnings.</h4>
                    <img src={m5}></img>
                    <h4>Explore Diverse Locations: Roam through the city's vibrant neighborhoods, each offering a unique set of challenges and opportunities. 
                        Adapt your menu to suit the tastes of the locals and make a name for yourself in each district.</h4>
                    <img src={m3}></img>
                    <h1>Missions:</h1>
                    <h4>Mission 1: Warm-Up Kitchen</h4>
                    <p>Location: Small Diner</p>
                    <p>Challenges: None. This mission serves to help players get comfortable with basic gameplay mechanics and the kitchen layout.</p>
                    <p>Recipes: Burger</p>
                    <h4>Mission 2: Greasy Grip</h4>
                    <p>Location: Busy Lunch Restaurant</p>
                    <p>Challenges: Occasional small oil spills around the cooking area that require quick cleanup to prevent slipping.</p>
                    <p>Recipes: Burger, Salad, Cheeseburger, Mega Burger, Mushroom Burger, Hawaii Burger</p>
                    <h1>Recipes:</h1>
                    <div className='mainfeatures-streetbites-gallery'>
                        <img src={saladso}></img>
                        <img src={burgerso}></img>
                        <img src={cheeseburgerso}></img>
                        <img src={mushroomburgerso}></img>
                        <img src={hawaiiburgerso}></img>
                        <img src={megaburgerso}></img>
                    </div>
                    <h1>Up-coming features:</h1>
                    <h4>Local/Online Co-op is going to be main focus so friends and families and couples can enjoy a nice time with a little challenge</h4>
                    <h4>Cinematics to every scene</h4>
                    <h4>Mission Completion unlockable items: better cutting knife, better stove and frying pan</h4>
                    <h4>Food truck upgrades aiming to make player deliver faster order resulting in increased tips</h4>
                    <h4>Restaurant scene challenges for example slippery floor to add flavor and difficulty to some scene</h4>
                    <h4>Other worlds that are focused on different gourmets: cookies, pizza, coffee, muffins etc.</h4>
                    <h4>Ambient sound tracks</h4>
                    <h4>Improved and stylized 3D environments and ingredients</h4>
                    <h1>Risks and challenges</h1>
                    <p>I am confident I will complete the project. 
                        I have already developed all base mechanics and systems and now i am designing the details like mission challenges, unlockable items and stylized assets as well as making some ambient background music.
                         I am very passionate about my game and in no way or i am going to give up on it until i finish all my planned features.</p>
                </div>
            </div>
            
    </body>
  )
}

export default StreetBites