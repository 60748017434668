import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import "./Navbar.css"
import logo from "../../Assets/transparent.png"
import product from "../../Assets/antique.png"
import play from "../../Assets/playbutton.png"
import details from "../../Assets/details.png"
import video from "../../Assets/website.mp4"
import extended from "../../Assets/exntended.png"
import extendedup from "../../Assets/exntended_up.png"
import filmic from "../../Assets/filmic.png"
import understairs from "../../Assets/understairs.png"
import bathroom from "../../Assets/bathroom.png"
import {FiSearch, FiShoppingCart,FiUser,FiMenu} from "react-icons/fi"
import {IoMdClose} from "react-icons/io"
import {BsTruck, BsPlay} from "react-icons/bs"
import {MdAdd} from "react-icons/md"
import {AiOutlineMinus} from "react-icons/ai"



function Navbar() {


  useEffect(() => {
    const showComercialBtn = document.querySelector("#show-commercial-btn");
          const commercial = document.querySelector("#commercial");
          //open video
          showComercialBtn.addEventListener('click', () => {
            commercial.style.display = "grid";
          });
          //close video
          document.body.addEventListener('click', (e) => {
            if(e.target.id === "commercial"){
              commercial.style.display = "none";
            }
          })
  })

  //open close side menu 
  useEffect(() => {
    const menuBtn = document.querySelector("#menu-btn");
    const closeBtn = document.querySelector("#close-btn");
    const menu = document.querySelector("nav .container__navbar ul");

    //open sidebar
    menuBtn.addEventListener('click', () => {
      menu.style.display = 'block';
      menuBtn.style.display = 'none';
      closeBtn.style.display ='inline-block';
    })

    //open sidebar
    closeBtn.addEventListener('click', () => {
      menu.style.display = 'none';
      menuBtn.style.display = 'inline-block';
      closeBtn.style.display ='none';
    })
  })

  //activate navbar section
  useEffect(() => {
    //change active class to click nav item
    const navItems = document.querySelectorAll('nav ul li');

    //remove actuve ckass from other item
    const removeActiveClass = () => {
      navItems.forEach(item => {
        const link = item.querySelector('a');
        link.classList.remove('active');
      })
    }

    //add active class to click nav item
    navItems.forEach(item => {
      const link = item.querySelector('a');
      link.addEventListener('click', () => {
        removeActiveClass();
        link.classList.add('active');
      })
    })
  })
  
  // //show hide faqs
  // useEffect(() => {
  //   const faqs = document.querySelectorAll('section#faqs article');

  //   faqs.forEach(faq => {
  //     faq.addEventListener('click', () => {
  //       faq.classList.toggle('open');
  //     })
  //   })
  // })

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     document.querySelector('nav__navbar').toggle('window-scroll', window.scrollY > 0)
  //   })
  // })

  return (
    <body className='body__navbar'>
      <nav className='nav__navbar'>
        <div className='container__navbar'>
            <a href="/" className='logo'><img className='img__navbar' src={logo} alt="" /></a>

            <ul>
                <li><a href="#" className='active'>Order</a></li>
                <li><a href="#products">Products</a></li>
                <li><a href="#why-choose-us">Why choose MD?</a></li>
                <li><a href="#faqs">FAQs</a></li>
                <li><a href="/">Let's talk</a></li>
            </ul>

            <div className='icons'>
                <a><i className='uil uil-search'><FiSearch/></i></a>
                <a><i className='uil uil-shopping-basket'><FiShoppingCart/></i></a>
                <a><i className='uil uil-user'><FiUser/></i></a>
            </div>

            <button id='menu-btn'><i className='uil uil-bars'><FiMenu/></i></button>
            <button id='close-btn'><i className='uil uil-multiply'><IoMdClose/></i></button>
        </div>
      </nav>

      <header>
        <div className='container__navbar'>
          <div className='info'>
            <div className='delivery'>
              <small>Customizable Deliveries</small>
              <i><BsTruck/></i>
            </div>
            <h1>Isometric Scenes & Realistic Renders</h1>
            <p>
              Mahersdesigns offers both Traditional and Modern designs with high definition renders
            </p>
            <div className='cta'>
              <a href="#products" className='btn btn-primary'>See Products</a>
              <a href="#" id='show-commercial-btn' ><img src={play} alt=""/>How it works</a>
            </div>
          </div>

          <div className='header-image'>
            <img src={product} alt="" />
          </div>
          <div className='details'>
            <img src={details} alt="" />
          </div>
        </div>
      </header>

      <section id='commercial'>
        <div className='container__navbar'>
          <video src="" controls autoPlay>
            <source src={video}  type="video/mp4"/>
          </video>
        </div>
      </section>

      <section id='products'>
        <div className='container__navbar'>
          <h1>Highest Quality Designs</h1>
          <p>
            Launched in 2022, Mahersdesigns designed many International projects. From a journey only on <a href="https://www.fiverr.com/mahersaalah?up_rollout=true">Fiverr</a>, we have grown significantly, making our 
            own brand available to almost everywhere a person can think of.
          </p>
          <article>
            <div className='image'>
              <img src={extended} alt="" />
            </div>
            <div className='info'>
              <h2>Isometric High definition Kitchen<br /><h6>+30 objects</h6></h2>
              <p>
                A Modern Kitchen Design with dinning section. Both the Chosen Color palette and the plants across the scene makes it fresh and appealing.
              </p>
              <a href="#">Read more</a>
            </div>
          </article>
          <article>
            <div className='info'>
              <h2>Filmic Styled Entrance<br /><h6>Traditional Color Palette</h6></h2>
              <p>
                
              </p>
              <a href="#">Read more</a>
            </div>
            <div className='image'>
              <img src={filmic} alt="" />
            </div>
          </article>
        </div>
      </section>

      <section id='why-choose-us'>
        <div className='container__navbar'>
          <div className='head'>
            <div className='left'>
              <h4>Why Choose Mahersdesigns</h4>
              <h2>How To Use? <br />learn more details</h2>
            </div>
            <div className='right'>
              <div className='image'>
                <img src={understairs} alt="" />
              </div>
              <div>
                <p>High definition</p>
                <h4>Customizable</h4>
              </div>
            </div>
          </div>

          <article>
            <div className='image'>
              <img src={bathroom} alt="" />
            </div>
            <div className='info'>
              <h4>Order & Relax</h4>
              <p>
                The high definition Designs offer realistic Internationmaterial to your Bathroom, kitchen or even balcony that when you buy your Tiles or Furniture you can simply show our Design.
              </p>
              <p>
                If you are looking to renovate your Bathroom, your Workspace or even your entire space our Designs will help you picture it in front of you.
              </p>
              <a href="#"><img src={play} alt="" /></a>
            </div>
          </article>
        </div>
      </section>

      <section id="faqs">
        <div className='container__navbar'>
          <h2>Fequently Asked Questions</h2>
          <div className='content'>
            <article className='open'>
              <div className='icon'><i><AiOutlineMinus/></i></div>
              <div className='detail'>
                <h4>How long until we deliver your product</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ratione at, laboriosam minus consectetur inventore voluptas corporis, commodi architecto non qui dolorem neque voluptatem velit doloribus possimus. Doloremque, tempora quasi?</p>
              </div>
            </article>

            <article>
              <div className='icon'><i><MdAdd/></i></div>
              <div className='detail'>
                <h4>How long until we deliver your product</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ratione at, laboriosam minus consectetur inventore voluptas corporis, commodi architecto non qui dolorem neque voluptatem velit doloribus possimus. Doloremque, tempora quasi?</p>
              </div>
            </article>

            <article>
              <div className='icon'><i><MdAdd/></i></div>
              <div className='detail'>
                <h4>How long until we deliver your product</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ratione at, laboriosam minus consectetur inventore voluptas corporis, commodi architecto non qui dolorem neque voluptatem velit doloribus possimus. Doloremque, tempora quasi?</p>
              </div>
            </article>

            <article>
              <div className='icon'><i><MdAdd/></i></div>
              <div className='detail'>
                <h4>How long until we deliver your product</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ratione at, laboriosam minus consectetur inventore voluptas corporis, commodi architecto non qui dolorem neque voluptatem velit doloribus possimus. Doloremque, tempora quasi?</p>
              </div>
            </article>
            
            <article>
              <div className='icon'><i><MdAdd/></i></div>
              <div className='detail'>
                <h4>How long until we deliver your product</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ratione at, laboriosam minus consectetur inventore voluptas corporis, commodi architecto non qui dolorem neque voluptatem velit doloribus possimus. Doloremque, tempora quasi?</p>
              </div>
            </article>
          </div>
          <Link to ="" className='btn'>Learn More</Link>
        </div>
      </section>
    </body>
  )
}

export default Navbar