import image1 from "../../Assets/UIUX/threedapp_page1.png"
import image2 from "../../Assets/UIUX/threedapp_page2.png"
import image3 from "../../Assets/UIUX/threedapp_page3.png"
import image4 from "../../Assets/UIUX/day-planner_page1.png"
import image5 from "../../Assets/UIUX/day-planner_page2.png"
import image6 from "../../Assets/Drinkapp/rules page.png"
import image7 from "../../Assets/Drinkapp/player page.png"
import image8 from "../../Assets/Drinkapp/categories page.png"
import image9 from "../../Assets/Drinkapp/questions page.png"

export default [image1,image2,image3,image4,image5,image6,image7,image8,image9]